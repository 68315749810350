export default function PasswordIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' className='w-8 h-8' viewBox='0 0 56 56'>
      <path
        fill='currentColor'
        d='M40.434 3.18c-6.61 0-12.985 4.547-12.985 14.226v7.078H7.785c-3.516 0-5.226 1.735-5.226 5.508v17.32c0 3.797 1.71 5.508 5.226 5.508H30.52c3.515 0 5.226-1.71 5.226-5.507v-17.32c0-3.516-1.477-5.25-4.523-5.462v-7.617c0-6.656 4.312-10.148 9.21-10.148c4.922 0 9.235 3.492 9.235 10.148v5.39c0 1.665.82 2.368 1.898 2.368c1.032 0 1.875-.633 1.875-2.297v-4.969c0-9.68-6.398-14.226-13.007-14.226M30.356 28.023c1.101 0 1.617.47 1.617 1.758V47.5c0 1.313-.516 1.805-1.617 1.805H7.948c-1.078 0-1.617-.492-1.617-1.805V29.781c0-1.289.54-1.758 1.617-1.758Z'
      />
    </svg>
  )
}
