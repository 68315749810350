export default function PosIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' className='h-8 w-8' viewBox='0 0 24 24'>
      <g fill='currentColor'>
        <path
          fillRule='evenodd'
          d='M9.939 3.25h4.122c1.843 0 3.302 0 4.444.153c1.174.158 2.125.49 2.875 1.238c1.03 1.027 1.28 2.44 1.359 4.317v.024c.008.164.015.331.008.472a1.28 1.28 0 0 1-.149.58a1.12 1.12 0 0 1-.268.312a2.55 2.55 0 0 1-.254.18c-.163.103-.385.227-.644.372l-.02.01a1.249 1.249 0 0 0 0 2.184l.02.01c.26.145.48.269.644.372c.085.054.174.114.254.18c.074.061.185.164.268.312c.12.214.143.43.15.58c.006.14-.001.308-.008.472l-.001.024c-.08 1.876-.33 3.29-1.359 4.317c-.75.748-1.7 1.08-2.875 1.238c-1.142.153-2.601.153-4.444.153H9.94c-1.843 0-3.302 0-4.444-.153c-1.175-.158-2.125-.49-2.875-1.238c-1.03-1.027-1.28-2.44-1.359-4.317v-.024a5.833 5.833 0 0 1-.009-.472c.007-.15.03-.366.15-.58a1.12 1.12 0 0 1 .268-.312a2.55 2.55 0 0 1 .254-.18c.163-.103.385-.227.644-.372l.02-.01a1.249 1.249 0 0 0 0-2.184l-.02-.01c-.26-.145-.48-.269-.644-.372a2.442 2.442 0 0 1-.254-.18a1.12 1.12 0 0 1-.268-.312a1.28 1.28 0 0 1-.15-.58a5.83 5.83 0 0 1 .01-.496c.078-1.876.328-3.29 1.358-4.317c.75-.748 1.7-1.08 2.875-1.238c1.141-.153 2.6-.153 4.444-.153Zm11.308 11.334v.004v-.004Zm.002.142v.02l-.009.233c-.078 1.856-.331 2.732-.92 3.318c-.424.424-1.006.678-2.015.813c-1.03.138-2.389.14-4.3.14h-4.01c-1.911 0-3.27-.002-4.3-.14c-1.01-.135-1.591-.389-2.016-.813c-.588-.586-.841-1.462-.92-3.318a15.1 15.1 0 0 1-.008-.253c.124-.077.303-.177.568-.325a2.749 2.749 0 0 0 0-4.802a17.255 17.255 0 0 1-.568-.325v-.02l.009-.233c.078-1.856.331-2.732.92-3.318c.424-.424 1.005-.678 2.015-.813c1.03-.138 2.389-.14 4.3-.14h4.01c1.911 0 3.27.002 4.3.14c1.01.135 1.591.389 2.016.813c.587.586.841 1.462.919 3.318l.009.233v.02c-.124.077-.303.177-.568.325a2.748 2.748 0 0 0 0 4.802c.264.148.444.248.568.325Zm.125-5.535h-.002a.036.036 0 0 1 .002 0Zm-.127.225v-.004v.004ZM2.627 9.19Zm.126.221v.004v-.004Zm0 5.172v.004v-.004Zm-.125.224a.046.046 0 0 1-.002.001h.002Zm18.744 0l.002.001a.031.031 0 0 1-.002 0ZM15.53 8.47a.75.75 0 0 1 0 1.06l-6 6a.75.75 0 0 1-1.06-1.06l6-6a.75.75 0 0 1 1.06 0Z'
          clipRule='evenodd'
        />
        <path d='M15.5 14.5a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm-5-5a1 1 0 1 1-2 0a1 1 0 0 1 2 0Z' />
      </g>
    </svg>
  )
}
