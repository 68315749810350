export default function TipIcon({ small }: { small?: boolean }) {
  const className = small ? 'w-5 h-5' : 'w-8 h-8'
  return (
    <svg xmlns='http://www.w3.org/2000/svg' className={className} viewBox='0 0 32 32'>
      <path
        fill='currentColor'
        d='M11 24h10v2H11zm2 4h6v2h-6zm3-26A10 10 0 0 0 6 12a9.19 9.19 0 0 0 3.46 7.62c1 .93 1.54 1.46 1.54 2.38h2c0-1.84-1.11-2.87-2.19-3.86A7.2 7.2 0 0 1 8 12a8 8 0 0 1 16 0a7.2 7.2 0 0 1-2.82 6.14c-1.07 1-2.18 2-2.18 3.86h2c0-.92.53-1.45 1.54-2.39A9.18 9.18 0 0 0 26 12A10 10 0 0 0 16 2z'
      />
    </svg>
  )
}
